<template>
  <NavQuestion
    v-bind:heading="'Your account'"
    v-bind:background="'bg-basket'"
    v-bind:subHeading="'Confirm your bank account details are correct'"
    v-bind:number="questionNumber"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="card-form">
            <p>
              <span class="bold">Account Holder:</span>
              {{ bankAccount.account_holder_name }}
            </p>
            <p>
              <span class="bold">Sort Code:</span>
              {{ directDebitForm.sortCode }}
            </p>
            <p>
              <span class="bold">Account Number:</span>
              {{ directDebitForm.accountNumber }}
            </p>
            <p>
              <span class="bold">Bank Name:</span>
              {{ bankAccount.bank_name }}
            </p>
          </div>
        </div>
      </div>

      <div class="align-left mt60 mb60">
        <img
          alt="Direct Debit payments by GoCardless"
          class="go-cardless mb30"
          src="https://mylastwill.s3.amazonaws.com/static/img/gocardless-small.png"
          width="211"
        />
        <br />
        <a
          class="link"
          href="https://gocardless.com/direct-debit/guarantee/"
          target="_blank"
          >The Direct Debit Guarantee</a
        >
      </div>
      <BaseModalSmall
        :no-slide="true"
        :isValidated="true"
        v-show="show.successModal"
        :disableClose="true"
        @confirm="next"
      >
        <template v-slot:header></template>
        <template
          v-if="
            mandate &&
            mandate.mandate &&
            mandate.gocardless &&
            mandate.gocardless.payments &&
            mandate.gocardless.payments.one_off_payment &&
            !mandate.gocardless.payments.subscription
          "
          v-slot:body
        >
          <h3 class="title mb30">
            MyLastWill is charging you £{{
              mandate.gocardless.payments.one_off_payment.amount / 100
            }}
          </h3>

          <p>
            We will debit your
            <span class="bold">{{ bankAccount.bank_name }}</span>
            ending
            <span class="bold"
              >*****{{ bankAccount.account_number_ending }}</span
            >
            by Direct Debit on or shortly after
            <span class="bold">{{
              mandate.gocardless.payments.one_off_payment.charge_date
            }}</span
            >.
          </p>

          <p>
            This payment and will appear on your bank statement as
            <span class="bold">mylastwill.co.uk</span> with the reference
            <span class="bold">{{ mandate.mandate.reference }}</span
            >. We will notify you by email 3 days in advance of each payment.
          </p>
          <p>
            If any of the above details are incorrect, please call us on
            <span class="bold">01785 338292</span> or email us at
            <span class="bold">payments@mylastwill.co.uk</span>
          </p>
          <div class="align-right mt60">
            <a
              class="link"
              href="https://gocardless.com/direct-debit/guarantee/"
              target="_blank"
            >
              <img
                src="https://mylastwill.s3.amazonaws.com/static/img/direct-debit-logo.jpg"
                alt="Direct Debit logo"
                width="170"
              />
            </a>
          </div>
        </template>
        <template
          v-else-if="
            mandate &&
            mandate.mandate &&
            mandate.gocardless &&
            mandate.gocardless.payments &&
            !mandate.gocardless.payments.one_off_payment &&
            mandate.gocardless.payments.subscription
          "
          v-slot:body
        >
          <h3 class="title mb30">
            A subscription of £{{
              mandate.gocardless.payments.subscription.amount / 100
            }},
            {{ mandate.gocardless.payments.subscription.interval_unit }}
            has been setup to MyLastWill Ltd
          </h3>

          <p>
            The first regular payment for this subscription will be debited from
            your
            <span class="bold">{{ bankAccount.bank_name }}</span>
            ending
            <span class="bold"
              >*****{{ bankAccount.account_number_ending }}</span
            >
            via Direct Debit on or shortly after
            <span class="bold">{{
              mandate.gocardless.payments.subscription.upcoming_payments[0]
                .charge_date
            }}</span
            >.
          </p>

          <p>
            Payments for this subscription will appear on your bank statement as
            <span class="bold">mylastwill.co.uk</span> with the reference
            <span class="bold">{{ mandate.mandate.reference }}</span
            >.
          </p>
          <p>
            If any of the above details are incorrect, please call us on
            <span class="bold">01785 338292</span> or email us at
            <span class="bold">payments@mylastwill.co.uk</span>
          </p>
          <div class="align-right mt60">
            <a
              class="link"
              href="https://gocardless.com/direct-debit/guarantee/"
              target="_blank"
            >
              <img
                src="https://mylastwill.s3.amazonaws.com/static/img/direct-debit-logo.jpg"
                alt="Direct Debit logo"
                width="170"
              />
            </a>
          </div>
        </template>
        <template v-else v-slot:body>
          <h3 class="title mb30">
            <span class="bold">Important:</span> confirmation of the setup of
            your Direct Debit Instruction to MyLastWill Ltd
          </h3>
          <p>This is to confirm that we have setup a Direct Debit with you.</p>
          <p v-if="mandate">
            Payments will be debited from your
            {{ bankAccount.bank_name }} ending *****{{
              bankAccount.account_number_ending
            }}, and will appear on your bank statement as
            <span class="bold">mylastwill.co.uk</span> with the reference
            <span class="bold">{{ mandate.mandate.reference }}</span
            >. We will notify you 3 working days before any new payments,
            subscriptions or changes to subscriptions.
          </p>
          <p>
            If any of the above details are incorrect, please call us on
            <span class="bold">01785 338292</span> or email us at
            <span class="bold">payments@mylastwill.co.uk</span>
          </p>
          <div class="align-right mt60">
            <a
              class="link"
              href="https://gocardless.com/direct-debit/guarantee/"
              target="_blank"
            >
              <img
                src="https://mylastwill.s3.amazonaws.com/static/img/direct-debit-logo.jpg"
                alt="Direct Debit logo"
                width="170"
              />
            </a>
          </div>
        </template>
        <template v-slot:button> Continue </template>
      </BaseModalSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validatePage"
        v-bind:forwardText="'Confirm'"
        v-bind:backLink="backTo"
        v-bind:loading="loading"
        @forwardClick="createMandate"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { checkoutHelpers } from '@/common/mixins/checkoutHelpers'
import { http } from '@/services'
import BaseModalSmall from '../../../../common/ui/BaseModalSmall'

export default {
  name: 'DirectDebitConfirmationPage',
  mixins: [checkoutHelpers],
  components: {
    BaseModalSmall,
    NavQuestionButtons,
    NavQuestion
  },
  data() {
    return {
      printing: null,
      show: {
        successModal: false
      },
      loading: false
    }
  },
  watch: {
    basket(value) {
      this.storage = value
    }
  },
  computed: {
    forwardTo() {
      return '/home'
    },
    backTo() {
      return '/pay/direct_debit'
    },
    validatePage() {
      return true
    },
    basket() {
      return this.$store.getters.checkoutBasket.printing
    },
    questionNumber() {
      return '6'
    },
    bankAccount() {
      return this.$store.getters.bankAccountSetup
    },
    mandate() {
      return this.$store.getters.directDebitMandate
    },
    directDebitForm() {
      return this.$store.getters.directDebitForm
    }
  },
  mounted() {
    if (!this.$store.getters.willPreview) {
      this.$store.dispatch('willPreviewFetch')
    }
    if (!this.bankAccount)
      this.$router.push('/pay').catch((error) => {
        console.log(error)
      })
  },
  beforeRouteLeave(to, from, next) {
    this.saveCheckoutOptions()
    next()
  },
  methods: {
    createMandate() {
      // guards: do not continue if already submitted
      if (this.loading) return
      // start
      this.loading = true
      http
        .post('/wills/api/direct_debit_setup_mandate')
        .then((response) => {
          this.loading = false
          this.$store.commit('directDebitMandate', response.data)
          this.show.successModal = true
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          this.$notification.error(error)
        })
    },
    next() {
      this.$router.push('/advice').catch((error) => {
        console.log(error)
      })
      this.$notification.success('Payment Complete')
    },
    goToDD() {}
  }
}
</script>

<style scoped>
.card-form {
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 60px;
  background-color: white;
}
.bold {
  font-weight: bolder;
  color: black;
}
.link {
  text-decoration: underline;
}
.title {
  color: #345a7a;
  font-size: 28px;
}
.title .bold {
  color: #345a7a;
}
@media screen and (max-width: 570px) {
  .card-form {
    padding: 10px;
    border-radius: 0;
  }
}
</style>
